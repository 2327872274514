.footer {
    width: 100%;
    background-color: var(--navbar-bg-color);
    color: var(--plain-text-color);
    position: absolute;
    bottom: 0;
    height: max-content;
}

.reactlogo {
    height: 4vh;
    /* background-color: ; */
}

.react-logos {
    /* margin-right: 5%; */
    float: right;
}

.madeusing {
    margin-left: 0rem;
}