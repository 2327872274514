@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100;200;300;400;500;600;700&display=swap');

html {
    scroll-behavior: smooth;
    width: 100%;
}

:root {
    --color-main: #209CEE;
    --color-main-grey: lightgrey;
    --color-navbar-grey: grey;
    --plain-text-color: black;
    --color-toggler: transparent;
    --bg-img: url(../src/assets/dot-grid.jpg);
    --navbar-bg-color: #e4d2ba;
}

.maindiv {
    background-image: var(--bg-img);
    position: relative;
    width: 100%;
    height: 100%;
}

[data-theme='dark'] {
    --plain-text-color: white;
    --color-navbar-grey: lightgrey;
    --color-toggler: rgba(255, 255, 255, 0.9);
    --bg-img: url(../src/assets/dot-grid-dark.png);
    --navbar-bg-color: #2a2727;
} 

body {
    font-family: Arima, sans-serif;
    transition: all 50s;
}

html,
body {
    /* height: 150%; */

    height: max-content;
}

#root {
    height: 100%;
}

/* .under_construction {
    margin-top: 5vh;
} */
