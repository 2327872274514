.about_wrapper {
    color: var(--plain-text-color) !important;
}

.about {
    padding-top: 20vh;
    height: 100vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.card {
    background-color: #00011f;
    height: 20%;
    width: 20%;
    border-radius: 15%;
}

.social {
    padding-top: 25%; 
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
}

.social_img {
    font-size: 10%;
    color: blanchedalmond;
    margin-left: auto;
    margin-right: auto;
}